import React from 'react';
import './About.scss';
import {ImLinkedin2, ImGithub, ImInstagram} from 'react-icons/im';
import {Link} from 'react-router-dom';

const About = () => {
  return (
    <div className="about">
      <div className="left">
        <h1>About me</h1>
      </div>
      <div className="right">
        <p className='desc'>
          Hey there! I’m a recent grad from NIT Silchar and previously worked as a SDE intern at 
          Hexfork. My skill set includes C++, JavaScript, 
          Node.js, React.js, HTML, CSS, DBMS, OOPs, OS and Networking. Apart from that, I love building personal projects 
          like “Entice – A skincare products ecommerce website” using React.js, JavaScript, and 
          “Fandom – A NFT Marketplace” which I designed with a responsive UI using HTML, CSS, and Bootstrap.
        </p>
        <p className='sub-desc'>Let's talk with me</p>
        <h4 className='email'>kurumojudeepak16@gmail.com</h4>
        <div className="icons">
          <Link to='https://www.linkedin.com/in/kurumoju-deepak-29bb8220a/' className='link'>
              <ImLinkedin2/>
          </Link>
          <Link to='https://github.com/deepu161102' className='link'>
              <ImGithub/>
          </Link>
          <Link to='https://www.instagram.com/deepu16_11/' className='link'>
              <ImInstagram/>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default About