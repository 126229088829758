import React from 'react';
import './Card.scss';
import { Link } from 'react-router-dom';
import {ImGithub} from 'react-icons/im';
import {IoGlobeSharp} from 'react-icons/io5';

const Card = ({item}) => {

  return (
    <div className="card">
      <div className="left">
        <Link to={item.link} className='link'>
            <div className="image">
              <img src={item.imageSrc} alt='' />
            </div>
        </Link>
      </div>
      <div className="right">
        <h2>{item.title}</h2>
        <p className="desc">
          {item.description}
        </p>
        <div className="icons">
          <Link to={item.srcLink} className='icon-link'>
            <ImGithub/>
            <span>Code</span>
          </Link>
          <Link to={item.link} className='icon-link'>
            <IoGlobeSharp/>
            <span>Website</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Card