import React from 'react'
import {ImLinkedin2, ImGithub, ImInstagram} from 'react-icons/im';
import './Footer.scss';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div className='footer'>
        <div className="layer">
            <div className="left">
                <span className='logo'>PORTFOLIO</span>
                <span className="copyright">© Copyright 2023. All Rights Reserved</span>
            </div>
            <div className="right">
                <div className="icons">
                    <Link to='https://www.linkedin.com/in/kurumoju-deepak-29bb8220a/' className='link'>
                        <ImLinkedin2/>
                    </Link>
                    <Link to='https://github.com/deepu161102' className='link'>
                        <ImGithub/>
                    </Link>
                    <Link to='https://www.instagram.com/deepu16_11/' className='link'>
                        <ImInstagram/>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}
